import { type SVGProps } from 'react'

const Html = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_128)">
        <path
          d="M3.9375 -0.7771H59.0625L54.0488 55.8258L31.4396 62.2229L8.95913 55.8232L3.9375 -0.7771ZM22.3939 24.8167L21.7849 17.6819L48.1898 17.6898L48.7935 10.807L14.2065 10.7992L16.0388 31.8254H39.9945L39.1388 40.8186L31.5 42.9291L23.7431 40.8029L23.2496 35.2641H16.401L17.2673 46.213L31.5 50.0193L45.6199 46.2314L47.5729 24.8193H22.3939V24.8167Z"
          fill="#E34F26"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_128">
          <rect width="63" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Html
