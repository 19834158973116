import { type SVGProps } from 'react'

const Sass = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_145)">
        <ellipse cx="27.9997" cy="28" rx="25.6667" ry="25.6667" fill="white" />
        <path
          d="M28 0C43.463 0 56 12.537 56 28C56 43.463 43.463 56 28 56C12.537 56 0 43.463 0 28C0 12.537 12.537 0 28 0ZM22.435 37.3287C22.8433 38.8337 22.799 40.2407 22.379 41.51L22.2273 41.93C22.1713 42.0723 22.106 42.21 22.0453 42.3407C21.7187 43.0173 21.2847 43.6473 20.7503 44.2307C19.1217 46.0017 16.849 46.6737 15.8737 46.109C14.8237 45.4977 15.3463 42.994 17.2363 40.999C19.2687 38.857 22.183 37.478 22.183 37.478V37.471L22.435 37.3287ZM45.5607 11.9863C44.296 7.00933 36.0477 5.37367 28.2427 8.148C23.6017 9.79767 18.5733 12.39 14.959 15.771C10.6587 19.7867 9.975 23.2867 10.2573 24.7497C11.2537 29.9087 18.3237 33.2827 21.231 35.7863V35.8003C20.3747 36.2203 14.1003 39.368 12.6303 42.6253C11.0553 46.0553 12.8753 48.5077 14.0653 48.8203C17.7403 49.8377 21.5203 47.9803 23.5503 44.9727C25.5103 42.0303 25.3377 38.2503 24.493 36.3953C25.6503 36.0803 27.013 35.9403 28.763 36.1527C33.6653 36.7127 34.6453 39.7927 34.433 41.0527C34.223 42.3103 33.2127 43.0453 32.8603 43.2553C32.5103 43.4677 32.4053 43.5353 32.438 43.6777C32.473 43.8877 32.6503 43.8877 32.928 43.8527C33.313 43.7827 35.4853 42.8027 35.5903 40.4203C35.6953 37.4103 32.823 34.0527 27.7153 34.1203C25.6153 34.1577 24.283 34.3327 23.3403 34.7177C23.2703 34.6127 23.198 34.5427 23.0953 34.4727C19.9453 31.0777 14.1003 28.6977 14.3453 24.1827C14.4153 22.5377 15.0103 18.2 25.5453 12.95C34.1903 8.64267 41.0877 9.835 42.2777 12.46C43.988 16.2027 38.6003 23.17 29.6053 24.1827C26.1753 24.5677 24.3903 23.24 23.933 22.7477C23.4453 22.2227 23.3753 22.1877 23.2003 22.295C22.9203 22.435 23.0953 22.89 23.2003 23.17C23.4803 23.87 24.5653 25.095 26.4577 25.725C28.1003 26.25 32.1277 26.5627 36.9577 24.675C42.3803 22.5773 46.6153 16.73 45.3903 11.83L45.5607 11.9863Z"
          fill="#CC6699"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_145">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Sass
