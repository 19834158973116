import { type SVGProps } from 'react'

const Mongodb = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_166)">
        <path
          d="M40.1169 22.295C37.1675 9.275 30.1955 4.99567 29.4465 3.36C28.7932 2.44067 28.2099 1.134 27.7315 0C27.6475 1.155 27.6032 1.59833 26.5112 2.76267C24.8242 4.08333 16.1559 11.354 15.4512 26.1427C14.7932 39.9373 25.4145 48.1577 26.8565 49.2053L27.0199 49.322C27.3201 51.5428 27.5769 53.7692 27.7899 56H28.9122C29.1782 53.592 29.5749 51.2027 30.1022 48.8367C31.0752 48.146 31.5115 47.7563 32.0855 47.2197C34.8038 44.705 36.964 41.6479 38.4266 38.246C39.8893 34.8441 40.6217 31.1731 40.5765 27.4703C40.5999 25.571 40.3362 23.5923 40.1169 22.295ZM27.6662 41.4167C27.6662 41.4167 27.6662 22.071 28.3079 22.0733C28.8049 22.0733 29.4512 47.0283 29.4512 47.0283C28.5622 46.9233 27.6662 42.9217 27.6662 41.4167Z"
          fill="#47A248"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_166">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Mongodb
