import { type SVGProps } from 'react'

const Express = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_119)">
        <path
          d="M56 43.372C55.185 43.6803 54.2853 43.6796 53.4708 43.3701C52.6562 43.0606 51.9831 42.4637 51.5784 41.692L43.5284 30.5597L42.3617 29.0034L33.0214 41.706C32.641 42.4453 32.0044 43.0208 31.2305 43.3249C30.4567 43.6289 29.5986 43.6407 28.8167 43.358L40.852 27.2113L29.6567 12.6257C30.4617 12.3356 31.342 12.3317 32.1495 12.6148C32.957 12.8978 33.6423 13.4505 34.09 14.1797L42.434 25.4497L50.8247 14.2263C51.208 13.5002 51.8448 12.9402 52.614 12.6528C53.3832 12.3654 54.2311 12.3707 54.9967 12.6677L50.652 18.4333L44.7674 26.0937C44.6039 26.2395 44.4732 26.4181 44.3836 26.618C44.2941 26.8178 44.2478 27.0344 44.2478 27.2534C44.2478 27.4723 44.2941 27.6889 44.3836 27.8887C44.4732 28.0886 44.6039 28.2672 44.7674 28.413L55.9767 43.3744L56 43.372ZM0.00469466 27.0107L0.984695 22.169C3.67736 12.5953 14.6534 8.61235 22.204 14.539C26.6257 18.0133 27.7294 22.932 27.5124 28.476H2.60403C2.20036 38.3764 9.34503 44.3544 18.48 41.3C19.9609 40.767 21.2864 39.8749 22.3379 38.7038C23.3893 37.5326 24.1338 36.1189 24.5047 34.5894C24.9877 33.0354 25.7834 32.7694 27.244 33.2174C27.0006 35.1129 26.3305 36.9286 25.2842 38.5278C24.2378 40.1271 22.8425 41.4682 21.203 42.4504C18.495 43.9316 15.388 44.5187 12.3262 44.1277C9.26434 43.7367 6.40456 42.3876 4.15569 40.2734C1.9321 37.7706 0.588206 34.6092 0.329028 31.2714C0.329028 30.723 0.142361 30.2097 0.0163613 29.7174C0.00500273 28.816 -0.000441833 27.9145 2.79913e-05 27.013L0.00469466 27.0107ZM2.63436 26.3433H25.1604C25.0204 19.166 20.4914 14.0747 14.4504 14.028C7.72569 13.9347 2.91436 18.914 2.61803 26.3107L2.63436 26.3433Z"
          fill="black"
          className="dark:fill-white"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_119">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Express
