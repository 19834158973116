import { type SVGProps } from 'react'

const Bootstrap = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.9346 28.6432H24.4752V21.1747H29.7655C32.6417 21.1747 34.2741 22.458 34.2741 24.7787C34.2741 27.2567 32.3738 28.6432 28.9297 28.6432H28.9346ZM29.7311 31.6079H24.4776V39.8384H29.9622C33.5489 39.8384 35.4492 38.4003 35.4492 35.6961C35.4492 32.992 33.4948 31.6079 29.7311 31.6079ZM59 29.0119V32.0062C56.227 32.2717 54.5332 34.3268 53.5277 37.5817C52.5272 40.8242 52.3895 44.8018 52.4952 47.8722C52.6059 51.0681 50.1156 54.0181 46.7501 54.0181H12.2548C8.88688 54.0181 6.39658 51.0681 6.50967 47.8722C6.61537 44.8018 6.47771 40.8242 5.47717 37.5817C4.46925 34.3268 2.773 32.2717 0 32.0062V29.0119C2.773 28.7464 4.47171 26.6912 5.47471 23.4364C6.47771 20.1939 6.61538 16.2163 6.50721 13.1458C6.39658 9.95 8.88688 7 12.2548 7H46.7501C50.118 7 52.6059 9.95 52.4952 13.1458C52.3895 16.2163 52.5272 20.1939 53.5277 23.4364C54.5332 26.6912 56.227 28.7464 59 29.0119ZM39.5128 35.937C39.5128 32.6085 37.17 30.2017 33.6915 29.8207V29.6806C36.2481 29.2651 38.2541 26.8904 38.2541 24.2403C38.2541 20.4618 35.2722 18.001 30.7267 18.001H20.5V43.0072H30.7783C36.2309 43.0072 39.5128 40.3375 39.5128 35.937Z"
        fill="#7952B3"
      />
    </svg>
  )
}

export default Bootstrap
