import { type SVGProps } from 'react'

const Tailwind = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_150)">
        <path
          d="M34.4404 13.775C25.2571 13.775 19.5175 18.3667 17.2217 27.55C20.6654 22.9584 24.6831 21.2365 29.2748 22.3844C31.8949 23.0387 33.766 24.9385 35.8409 27.0449C39.2186 30.4715 43.1244 34.4375 51.6592 34.4375C60.8425 34.4375 66.5821 29.8459 68.8779 20.6625C65.4342 25.2542 61.4165 26.9761 56.8248 25.8281C54.2047 25.1738 52.3336 23.274 50.2587 21.1676C46.8839 17.7411 42.9781 13.775 34.4404 13.775ZM17.2217 34.4375C8.03835 34.4375 2.29876 39.0292 0.00292969 48.2125C3.44668 43.6209 7.46439 41.899 12.0561 43.0469C14.6762 43.7012 16.5473 45.601 18.6221 47.7074C21.9999 51.134 25.9057 55.1 34.4404 55.1C43.6238 55.1 49.3633 50.5084 51.6592 41.325C48.2154 45.9167 44.1977 47.6386 39.6061 46.4906C36.9859 45.8363 35.1148 43.9365 33.04 41.8301C29.6651 38.4036 25.7593 34.4375 17.2217 34.4375Z"
          fill="#06B6D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_150">
          <rect width="68.875" height="68.875" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Tailwind
