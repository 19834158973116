import { type SVGProps } from 'react'

const Javascript = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_136)">
        <rect
          x="11.2002"
          y="17.7009"
          width="42"
          height="32.6667"
          fill="white"
        />
        <path
          d="M0 0H56V56H0V0ZM51.4127 42.644C51.0043 40.089 49.3407 37.9423 44.4057 35.9403C42.6883 35.1353 40.7797 34.5753 40.2127 33.2803C40.0003 32.5103 39.9677 32.0903 40.1053 31.6353C40.4553 30.128 42.2403 29.6753 43.6403 30.0953C44.5503 30.3753 45.3903 31.0753 45.9177 32.1953C48.3303 30.618 48.3303 30.618 50.0127 29.5703C49.3827 28.5903 49.07 28.168 48.6453 27.7503C47.1753 26.1053 45.2177 25.2653 42.0327 25.3377L40.3877 25.5453C38.8103 25.9303 37.3077 26.7703 36.3977 27.8903C33.7377 30.9027 34.5053 36.1527 37.7253 38.3227C40.9103 40.7027 45.5677 41.2253 46.1627 43.4677C46.7227 46.1977 44.1327 47.0727 41.5753 46.7577C39.683 46.3377 38.6353 45.3903 37.4803 43.6403L33.2103 46.0927C33.7003 47.2127 34.2603 47.7003 35.1003 48.6803C39.1603 52.7777 49.3103 52.5677 51.1327 46.3377C51.2003 46.1277 51.6927 44.6927 51.3053 42.4877L51.4127 42.644ZM30.4523 25.739H25.207C25.207 30.261 25.186 34.755 25.186 39.284C25.186 42.1587 25.333 44.7977 24.864 45.6097C24.094 47.2173 22.1107 47.012 21.21 46.7297C20.286 46.2723 19.817 45.6423 19.2733 44.7347C19.1263 44.4897 19.0167 44.2773 18.977 44.2773L14.7187 46.9023C15.4303 48.3723 16.4687 49.637 17.808 50.442C19.803 51.632 22.484 52.017 25.291 51.387C27.118 50.8597 28.693 49.7747 29.5167 48.0947C30.7067 45.9247 30.4547 43.2647 30.443 40.2873C30.471 35.4947 30.443 30.6997 30.443 25.8697L30.4523 25.739Z"
          fill="#F7DF1E"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_136">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Javascript
