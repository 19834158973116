import { type IconType } from 'react-icons'
import {
  MdMoreTime,
  MdOutlineComputer,
  MdOutlineRocketLaunch,
  MdPeopleOutline,
} from 'react-icons/md'

import Bootstrap from '@/components/SVGs/bootstrap'
import Css from '@/components/SVGs/css'
import Express from '@/components/SVGs/express'
import Html from '@/components/SVGs/html'
import Javascript from '@/components/SVGs/javascript'
import Mongodb from '@/components/SVGs/mongodb'
import Mui from '@/components/SVGs/mui'
import Mysql from '@/components/SVGs/mysql'
import Nextjs from '@/components/SVGs/nextjs'
import Nodejs from '@/components/SVGs/nodejs'
import PostgreSql from '@/components/SVGs/postgresql'
import Pwa from '@/components/SVGs/pwa'
import ReactJs from '@/components/SVGs/react'
import Sass from '@/components/SVGs/sass'
import Tailwind from '@/components/SVGs/tailwindcss'

import {
  type FilterDataType,
  type SkillSetType,
  type WorkExpDataType,
} from './types'

export const SKILLS_SET: SkillSetType[] = [
  { id: '1', name: 'HTML', iconKey: 'Html', rate: 10 },
  { id: '2', name: 'CSS', iconKey: 'Css', rate: 10 },
  { id: '3', name: 'Javascript', iconKey: 'Javascript', rate: 8.5 },
  { id: '4', name: 'SASS', iconKey: 'Sass', rate: 7 },
  { id: '5', name: 'Tailwind CSS', iconKey: 'Tailwind', rate: 8 },
  { id: '6', name: 'Bootstrap', iconKey: 'Bootstrap', rate: 7 },
  { id: '7', name: 'Material UI', iconKey: 'Mui', rate: 7 },
  { id: '8', name: 'React JS', iconKey: 'ReactJs', rate: 8 },
  { id: '9', name: 'Next JS', iconKey: 'Nextjs', rate: 8 },
  { id: '10', name: 'Node JS', iconKey: 'Nodejs', rate: 7 },
  { id: '11', name: 'Express JS', iconKey: 'Express', rate: 6.5 },
  { id: '12', name: 'Postgre SQL', iconKey: 'PostgreSql', rate: 6.5 },
  { id: '13', name: 'MySQL', iconKey: 'Mysql', rate: 5 },
  { id: '14', name: 'Mongo DB', iconKey: 'Mongodb', rate: 5 },
  { id: '15', name: 'PWA', iconKey: 'Pwa', rate: 5 },
]

export const TECH_NAMES_BY_ID = SKILLS_SET.map(({ name }) => name)

export const SKILL_ICONS: Record<string, IconType> = {
  Html,
  Css,
  Javascript,
  Sass,
  Tailwind,
  Bootstrap,
  Mui,
  ReactJs,
  Nextjs,
  Nodejs,
  Express,
  PostgreSql,
  Mysql,
  Mongodb,
  Pwa,
}

export const WORKS_EXP: WorkExpDataType[] = [
  {
    id: '1',
    title: 'Freelance - Upwork',
    desc: 'work_desc_1',
    period: 'January, 2021 - January, 2022',
    imageSrc: '/images/work_1.webp',
    detailUrl: 'https://www.upwork.com/freelancers/~019597f410be18ce47',
  },
  {
    id: '2',
    title: 'PT. Solusi Nusantara Technologi (Nusantech)',
    desc: 'work_desc_2',
    period: 'February, 2022 - January, 2023',
    imageSrc: '/images/work_2.webp',
    detailUrl:
      'https://drive.google.com/file/d/1fqvUMgDuzMcjDI0twFnxom9vD4wIuIPo/view?usp=sharing',
  },
]

export const ADVANTAGES_ICONS = [
  MdOutlineRocketLaunch,
  MdPeopleOutline,
  MdMoreTime,
  MdOutlineComputer,
]

export const BLOG = [
  {
    id: '1',
    title: 'My Article about lorem Ipsum Dolor sit amet',
    tags: ['Javascript', 'React JS'],
    desc: 'My Article about lorem Ipsum Dolor sit amet  is spectrum. My Article about lorem Ipsum Dolor sit amet',
    thumbnail: '/images/blog_1.webp',
  },
  {
    id: '2',
    title: 'My Article about lorem Ipsum Dolor sit',
    tags: ['Javascript', 'React JS'],
    desc: 'My Article about lorem Ipsum Dolor sit amet  is spectrum. My Article about lorem Ipsum Dolor sit amet',
    thumbnail: '/images/blog_1.webp',
  },
  {
    id: '3',
    title: 'My Article about lorem Ipsum Dolor sit amet',
    tags: ['Javascript', 'React JS'],
    desc: 'My Article about lorem Ipsum Dolor sit amet  is spectrum. My Article about lorem Ipsum Dolor sit amet',
    thumbnail: '/images/blog_1.webp',
  },
]

export const TESTI = [
  {
    id: '1',
    text: '"It was a pleasure to work with this freelancer! Thanks a lot for the quick turn around and the quality of the code written!"',
    name: 'Philipp Lammers',
    date: 'Sep 2, 2021',
    rating: 5,
  },
  {
    id: '2',
    text: '"Diqi did again a super job. This project was just about adjusting a couple of things on the website that he build previously. It took him less than a day to deliver what I asked for! Can highly recommend this freelancer - he continues to deliver great work! And his code quality is top notch!!"',
    name: 'Philipp Lammers',
    date: 'Jun 29, 2021',
    rating: 5,
  },
  {
    id: '3',
    text: 'Very good and easily understand your requirement. It was good to work with him."',
    name: 'Swapnil Gandhi',
    date: 'Mar 21, 2021',
    rating: 5,
  },
]

export const FILTER_WORKS: FilterDataType[] = [
  {
    key: 'categories',
    label: 'categories',
    values: ['Design to Code', 'Web App', 'Frontend', 'Backend'],
  },
  {
    key: 'technologies',
    label: 'technologies',
    values: [
      'React JS',
      'Next JS',
      'Express JS',
      'PostgreSQL',
      'Typescript',
      'Tailwind CSS',
      'SASS',
      'Node JS',
    ],
  },
  {
    key: 'tags',
    label: 'tags',
    values: ['Finance', 'ERP', 'Chat GPT', 'Portfolio'],
  },
]
