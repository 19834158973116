import { type SVGProps } from 'react'

const Nextjs = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_132)">
        <path
          d="M29.4129 0C28.9648 0 28.6255 0.00330417 28.502 0.0170292C28.3708 0.0305 27.9532 0.0704042 27.5778 0.100396C18.9138 0.88145 10.7985 5.55558 5.65851 12.7396C2.79634 16.7343 0.965833 21.2651 0.2745 26.0648C0.0299917 27.7398 0 28.2346 0 30.5061C0 32.7776 0.0305 33.2724 0.2745 34.9479C1.93167 46.4007 10.083 56.0232 21.1383 59.5881C23.118 60.2263 25.2049 60.6614 27.5781 60.9238C28.5022 61.0254 32.4972 61.0254 33.4214 60.9238C37.5178 60.4706 40.9884 59.4572 44.4108 57.7103C44.9356 57.4419 45.0371 57.3705 44.9656 57.3105C44.9179 57.2752 42.6814 54.2763 39.9985 50.6514L35.121 44.0634L29.0091 35.0193C25.6462 30.0468 22.8793 25.9812 22.8554 25.9812C22.8315 25.9746 22.8079 29.9937 22.7957 34.8999C22.7787 43.4912 22.7721 43.8369 22.6645 44.0397C22.5095 44.332 22.39 44.451 22.1399 44.5821C21.9493 44.6772 21.7821 44.6952 20.8818 44.6952H19.8499L19.5759 44.5224C19.406 44.4159 19.268 44.2656 19.1764 44.0872L19.0511 43.8188L19.0645 31.8654L19.0816 19.9058L19.2661 19.6733C19.3617 19.548 19.5645 19.3873 19.7073 19.3098C19.9518 19.1903 20.0474 19.1784 21.0788 19.1784C22.2955 19.1784 22.4981 19.2259 22.814 19.5716C22.9037 19.6674 26.213 24.6516 30.1721 30.6553C34.1808 36.732 38.1919 42.8071 42.2054 48.8806L47.0351 56.196L47.2791 56.0351C49.4441 54.628 51.7333 52.6247 53.546 50.5382C57.404 46.1084 59.8908 40.7071 60.7255 34.9477C60.9697 33.2724 60.9995 32.7771 60.9995 30.5061C60.9995 28.2346 60.969 27.7397 60.7255 26.0643C59.0678 14.6115 50.9167 4.98904 39.8617 1.4241C37.9118 0.791983 35.8367 0.356596 33.5114 0.0942958C33.0818 0.0495625 30.7575 0.00127083 29.4134 0.000254167L29.4129 0ZM39.7537 18.3432C40.6364 18.3432 40.7912 18.3567 40.9882 18.4627C41.2746 18.6055 41.5067 18.88 41.5906 19.1659C41.6378 19.321 41.65 22.6361 41.6378 30.1063L41.6208 40.8258L39.7308 37.9283L37.8345 35.0308V27.238C37.8345 22.2004 37.8581 19.3683 37.894 19.2313C37.9893 18.8975 38.1979 18.635 38.4844 18.4802C38.7286 18.3546 38.8181 18.343 39.7545 18.343L39.7537 18.3432Z"
          fill="black"
          className="dark:fill-white"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_132">
          <rect width="61" height="61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Nextjs
