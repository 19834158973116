import { type SVGProps } from 'react'

const Pwa = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="65"
      height="41"
      viewBox="0 0 65 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.7827 9L65 33.4397H58.2026L56.6275 29.0624H47.8384L49.7161 24.3205H55.1379L52.565 17.1268L55.7827 9ZM33.8154 9L38.6151 24.7936L45.3646 9H51.9063L42.039 33.4397H35.5842L31.1439 19.2763L26.4867 33.4397H20.049L16.7096 27.7163L19.9986 17.5966L23.6332 24.7936L28.7869 9H33.8152H33.8154ZM10.1302 9C13.0106 9 15.1984 9.82629 16.6936 11.4791C16.9756 11.7885 17.2299 12.1219 17.4536 12.4755L14.5413 21.4357L13.4994 24.6419C12.5461 24.914 11.4592 25.05 10.2386 25.05H6.20967V33.44H0V9H10.1302ZM8.55508 13.7419H6.20967V20.3078H8.53802C10.0485 20.3078 11.0855 19.9849 11.6485 19.339C12.162 18.7575 12.4191 17.971 12.4191 16.9794C12.4191 15.9949 12.1268 15.2095 11.5429 14.6225C10.9587 14.0356 9.96287 13.7419 8.55508 13.7419Z"
        fill="#5A0FC8"
      />
    </svg>
  )
}

export default Pwa
