import { type SVGProps } from 'react'

const Css = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_123)">
        <rect x="13" y="10.4806" width="39" height="45.5" fill="white" />
        <path
          d="M4.0625 0.806152H60.9375L55.7646 59.2059L32.4377 65.8062L9.24083 59.2032L4.0625 0.806152ZM50.3479 12.758L14.6521 12.7499L15.229 19.8512L42.6508 19.8566L41.9602 27.2124H23.9769L24.6269 34.1809H41.3698L40.3785 43.7224L32.4973 45.8999L24.4915 43.7062L23.9823 37.9916H16.9135L17.699 48.4322L32.5 53.0445L47.0519 48.9007L50.3479 12.7607V12.758Z"
          fill="#1572B6"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_123">
          <rect width="65" height="65" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Css
