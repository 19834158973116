import { type SVGProps } from 'react'

const PostgreSql = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_178)">
        <path
          d="M40.0424 39.3103C40.3862 36.4479 40.2828 36.0302 42.4133 36.4922L42.9554 36.5407C44.5944 36.6145 46.7375 36.277 48.0179 35.6927C50.7285 34.4355 52.3358 32.3346 49.6632 32.8872C43.5671 34.1444 43.1242 32.0794 43.1242 32.0794C49.5641 22.5239 52.2578 10.395 49.9311 7.42709C43.5946 -0.672914 32.6237 3.15771 32.4402 3.25896L32.3811 3.26951C31.1788 3.01638 29.8499 2.86873 28.31 2.84763C25.551 2.80545 23.4585 3.57115 21.868 4.7756C21.868 4.7756 2.29932 -3.28643 3.20846 14.9154C3.40252 18.7882 8.75612 44.2357 15.1475 36.5365C17.4868 33.7226 19.7438 31.3453 19.7438 31.3453C20.8618 32.0899 22.2055 32.4696 23.6124 32.3325L23.7221 32.2397C23.6897 32.6043 23.7046 32.9715 23.7664 33.3323C22.1211 35.1717 22.6063 35.4944 19.3156 36.1715C15.9871 36.8571 17.9446 38.0784 19.2186 38.399C20.7669 38.7872 24.3486 39.3356 26.7702 35.9479L26.6731 36.334C27.3186 36.8508 27.2743 40.0465 27.3692 42.3288C27.4642 44.6111 27.6139 46.7437 28.0801 47.9988C28.5463 49.2539 29.0926 52.4918 33.4168 51.5847C37.0302 50.8106 39.7935 49.6947 40.0466 39.3293"
          fill="black"
        />
        <path
          d="M40.0424 39.3103C40.3862 36.4479 40.2828 36.0302 42.4133 36.4922L42.9554 36.5407C44.5944 36.6145 46.7375 36.277 48.0179 35.6927C50.7285 34.4355 52.3358 32.3346 49.6632 32.8872C43.5671 34.1444 43.1242 32.0794 43.1242 32.0794C49.5641 22.5239 52.2578 10.395 49.9311 7.42709C43.5946 -0.672914 32.6237 3.15771 32.4402 3.25896L32.3811 3.26951C31.1788 3.01638 29.8499 2.86873 28.31 2.84763C25.551 2.80545 23.4585 3.57115 21.868 4.7756C21.868 4.7756 2.29932 -3.28643 3.20846 14.9154C3.40252 18.7882 8.75612 44.2357 15.1475 36.5365C17.4868 33.7226 19.7438 31.3453 19.7438 31.3453C20.8618 32.0899 22.2055 32.4696 23.6124 32.3325L23.7221 32.2397C23.6897 32.6043 23.7046 32.9715 23.7664 33.3323C22.1211 35.1717 22.6063 35.4944 19.3156 36.1715C15.9871 36.8571 17.9446 38.0784 19.2186 38.399C20.7669 38.7872 24.3486 39.3356 26.7702 35.9479L26.6731 36.334C27.3186 36.8508 27.2743 40.0465 27.3692 42.3288C27.4642 44.6111 27.6139 46.7437 28.0801 47.9988C28.5463 49.2539 29.0926 52.4918 33.4168 51.5847C37.0302 50.8106 39.7935 49.6947 40.0466 39.3293"
          stroke="black"
          strokeWidth="5.3725"
        />
        <path
          d="M49.6445 32.9062C43.5484 34.1634 43.1054 32.0983 43.1054 32.0983C49.5454 22.5429 52.239 10.4139 49.9145 7.44605C43.5779 -0.653946 32.6071 3.17879 32.4236 3.27793L32.3645 3.28848C31.0245 3.01961 29.6621 2.87765 28.2955 2.86449C25.5364 2.8223 23.4439 3.58801 21.8535 4.79246C21.8535 4.79246 2.2827 -3.26957 3.19395 14.9322C3.38801 18.805 8.7416 44.2525 15.133 36.5533C17.4871 33.7225 19.7441 31.3453 19.7441 31.3453C20.8621 32.0899 22.2057 32.4696 23.6127 32.3325L23.7224 32.2396C23.6902 32.6043 23.705 32.9715 23.7667 33.3323C22.1214 35.1717 22.6065 35.4944 19.3159 36.1715C15.9873 36.8571 17.9448 38.0784 19.2189 38.399C20.7672 38.7871 24.3489 39.3356 26.7704 35.9479L26.6734 36.3339C27.3189 36.8507 27.7703 39.6942 27.6943 42.2718C27.6184 44.8495 27.5678 46.6171 28.074 48.0009C28.5803 49.3846 29.0865 52.4939 33.4107 51.5868C37.0241 50.8127 38.8951 48.8025 39.1588 45.457C39.3444 43.0776 39.7621 43.4278 39.7916 41.3015L40.1291 40.2932C40.5151 37.0659 40.1924 36.026 42.4178 36.5111L42.9599 36.5597C44.5989 36.6335 46.7441 36.296 48.0013 35.7117C50.7118 34.4545 52.3171 32.3536 49.6445 32.9062Z"
          fill="#336791"
        />
        <path
          d="M27.0295 34.735C26.8607 40.7383 27.0717 46.7838 27.6581 48.235C28.2445 49.6863 29.5038 52.5593 33.8301 51.6353C37.4435 50.8612 38.7597 49.3614 39.3292 46.0518L40.6645 35.4691M21.8446 4.64058C21.8446 4.64058 2.26119 -3.36661 3.17244 14.8352C3.36651 18.708 8.7201 44.1555 15.1115 36.4563C17.4445 33.6445 19.5539 31.4444 19.5539 31.4444M32.421 3.17878C31.746 3.38972 43.3139 -1.05052 49.8909 7.35112C52.2112 10.319 49.5217 22.4479 43.0818 32.0034"
          stroke="white"
          strokeWidth="1.79"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.0839 31.9991C43.0839 31.9991 43.5058 34.0663 49.623 32.8049C52.2956 32.2523 50.6861 34.3532 47.9777 35.6104C45.7544 36.644 40.7678 36.9077 40.6856 35.4838C40.4746 31.803 43.3096 32.9209 43.105 31.9991C42.9194 31.168 41.6496 30.3538 40.8143 28.3204C40.0823 26.5485 30.7736 12.943 43.3961 14.9617C43.8602 14.8668 40.1055 2.93828 28.293 2.76531C16.4805 2.59234 16.8539 17.2884 16.8539 17.2884"
          stroke="white"
          strokeWidth="1.79"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M23.7242 33.2606C22.0789 35.1 22.564 35.4227 19.2734 36.0998C15.9448 36.7854 17.9023 38.0067 19.1764 38.3273C20.7247 38.7154 24.3064 39.2639 26.7279 35.8741C27.4662 34.8405 26.7237 33.1952 25.7112 32.7775C25.2219 32.575 24.5679 32.3219 23.7284 33.2627L23.7242 33.2606Z"
          stroke="white"
          strokeWidth="1.79"
          strokeLinejoin="round"
        />
        <path
          d="M23.6168 33.2289C23.4481 32.1468 23.9712 30.8622 24.5302 29.3561C25.3697 27.0991 27.3061 24.8421 25.7578 17.6765C24.604 12.3398 16.8563 16.5649 16.8563 17.2884C16.8563 18.0119 17.2064 20.9587 16.7297 24.3864C16.1032 28.8625 19.5774 32.6467 23.5767 32.2607"
          stroke="white"
          strokeWidth="1.79"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7733 17.1809C21.7375 17.4277 22.2269 18.0879 22.8618 18.1765C23.4967 18.2651 24.0388 17.7504 24.0747 17.5015C24.1105 17.2526 23.6212 16.9826 22.9862 16.894C22.3513 16.8054 21.805 16.9362 21.7733 17.1809Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5975"
        />
        <path
          d="M41.1036 16.6767C41.1373 16.9235 40.6501 17.5837 40.0151 17.6723C39.3802 17.7609 38.8339 17.2462 38.8022 16.9973C38.7706 16.7484 39.2558 16.4784 39.8907 16.3898C40.5256 16.3012 41.0719 16.432 41.1036 16.6767Z"
          fill="white"
          stroke="white"
          strokeWidth="0.2975"
        />
        <path
          d="M43.3729 14.9659C43.4784 16.9066 42.9553 18.2249 42.8878 20.29C42.7907 23.2895 44.3179 26.7236 42.0166 30.1619"
          stroke="white"
          strokeWidth="1.79"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_536_178">
          <rect width="54" height="54" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PostgreSql
